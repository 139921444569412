import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "create-and-connect-with-better-tools",
      "style": {
        "position": "relative"
      }
    }}>{`Create and Connect with Better Tools`}<a parentName="h1" {...{
        "href": "#create-and-connect-with-better-tools",
        "aria-label": "create and connect with better tools permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`The idea of this section is simple: you will only find the most useful tools here. This is not meant as a comprehensive and never-ending list of every tool and best practice available: it is a highly curated collection of things that have genuinely changed our day-to-day lives as developers, designers, product managers, and dreamers.`}</p>
    <h2 {...{
      "id": "not-another-awesome-list",
      "style": {
        "position": "relative"
      }
    }}>{`Not Another Awesome List`}<a parentName="h2" {...{
        "href": "#not-another-awesome-list",
        "aria-label": "not another awesome list permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Kernel is intentional about not presenting you with endless lists of resources. When we are trying to learn about a new field, and find ourselves confronted with a list of 100+ possibilities, the experience is one of anxiety: "there's so much to know, and I know so little, so where should I even start? I suppose it's not really worth it after all."`}</p>
    <p>{`Here you will find only things that `}<strong parentName="p">{`help you do cool stuff`}</strong>{`. Thinking skills and readings live in the `}<a parentName="p" {...{
        "href": "/learn/"
      }}>{`Learn Modules`}</a>{`: this section is meant to get you building, playing, and `}<a parentName="p" {...{
        "href": "/learn/module-1/joyful-subversion"
      }}>{`joyfully subverting the status quo`}</a>{`.`}</p>
    <h2 {...{
      "id": "live-in-the-layers",
      "style": {
        "position": "relative"
      }
    }}>{`Live in the Layers`}<a parentName="h2" {...{
        "href": "#live-in-the-layers",
        "aria-label": "live in the layers permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`One of the most interesting aspects of the people we have met in web3 is the interdisciplinary nature of many of their interests. No-one is just a designer, or only a developer, or particpating in all this because of a single idea or product. While many of us do have particular expertise, the point of useful tools ought to be to help us `}<a parentName="p" {...{
        "href": "/learn/module-2/better-questions"
      }}>{`ask ever better questions`}</a>{` of ourselves, our environment, and the worlds we create together.`}</p>
    <p>{`Knowing such interesting and interested people is liberating, though it does make categorization more challenging. We do not wish to box you in with the categories presented here: each one links to each of the others, and we encourage you to explore all the sections and think how you can use the tools placed in each to `}<a parentName="p" {...{
        "href": "/build/games/infinite"
      }}>{`play with the boundaries`}</a>{` between each field.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      